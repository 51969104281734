<template>
  <form @submit.prevent="save" v-if="program" class="d-flex">
    <div v-if="isEditing" :class="{ 'is-busy': isSaving }" class="input-group">
      <input
        v-model.trim="name"
        ref="input"
        class="form-control"
        type="text"
      >
      <SubmitButton
        :text="'Save'"
        :textBusy="'Saving...'"
        :isLoading="isSaving"
        :disabled="name === ''"
        class="btn btn-primary"
      ></SubmitButton>
    </div>
    <div
      @click="toggleEditing"
      v-if="!isEditing"
      class="d-flex align-items-center"
      role="button"
    >
      <PageSubtitle :subtitle="name"></PageSubtitle>
      <IconButton :icon="'pen'" class="btn ms-3 pe-none"></IconButton>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProgramNameField',
  components: {
    PageSubtitle: () => import('@/components/PageSubtitle'),
    IconButton: () => import('@/components/button/IconButton'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('program', ['program']),
  },
  methods: {
    reset() {
      this.name = this.program.name;
    },
    toggleEditing() {
      this.isEditing = !this.isEditing;
      if (this.isEditing === true) {
        this.$nextTick(() => this.$refs.input.focus());
      }
    },
    cancelEditing() {
      this.isEditing = false;
      this.reset();
    },
    async save() {
      const { name } = this;
      if (name === this.program.name) {
        this.cancelEditing();
        return;
      }

      this.isSaving = true;
      try {
        const { programId } = this.program;
        const { userId } = this.authUser;
        await this.$store.dispatch('program/updateProgram', {
          programId,
          authorId: userId,
          name,
        });
        this.cancelEditing();
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isSaving = false;
      }
    },
  },
  data() {
    return {
      isEditing: false,
      isSaving: false,
      name: '',
    };
  },
  mounted() {
    this.reset();
  },
};
</script>
